<template>
  <v-alert class="success-alert" v-if="this.$store.state.success" color="var(--app-green-1)" type="success">
    {{ this.$store.state.successText }}
  </v-alert>
</template>
<script>
export default {
  data: () => ({

  }),
  methods: {},
  computed: {

  },
  mounted() {
  },
};
</script>

<style scoped lang="scss">
.success-alert {
  z-index: 1000;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  min-height: auto !important;

  i {
    margin-right: 20px;
  }
}


@media screen and (max-width: 640px) {
  .success-alert {
    font-size: 16px !important;
    min-height: auto !important;
  }
}
</style>