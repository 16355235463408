import Vue from "vue";
import VueI18n from "vue-i18n";
import EN from "./locales/en.json";
import RU from "./locales/ru.json";


Vue.use(VueI18n);

export default new VueI18n({
  locale: "RU",
  fallbackLocale: "RU",
  messages: { EN, RU, },
});



/*   created() {
  if (this.$store.state.userLang == "ru-RU") {
    this.i18n.locale = "RU";
  }
  else if (this.$store.state.userLang == "en-US") {
    this.i18n.locale = "EN";
  }
   else if (this.$store.state.userLang == "es-AR") {
    this.i18n.locale = "ES";
  }
   else if (this.$store.state.userLang == "zh-CN") {
    this.i18n.locale = "CN";
  }
  else{
    this.i18n.locale = "EN";
  }
}, */
