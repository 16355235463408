import Vue from "vue";

Vue.mixin({
  methods: {
    roleEditUser(val) {
      return this.$store.getters.editRoleUser(val);
    },
    roleAdminUser(val) {
      return this.$store.getters.adminRoleUser(val);
    },
    checkValidUrl(val) {
      if (val) {
        const url = val.toLowerCase();
        if (url.indexOf("https://") == 0 || url.indexOf("http://") == 0) {
          return true
        } else {
          return 'В начале ссылки должно быть https:// или http://'
        }
      }
    },
    declinationOfWords(value, words) {
      value = Math.abs(value) % 100;
      var num = value % 10;
      if (value > 10 && value < 20) return words[2];
      if (num > 1 && num < 5) return words[1];
      if (num == 1) return words[0];
      return words[2];
    },
    validatorMail(mail) {
      // const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      if (mail) {
        const reg = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (reg.test(mail) == false) {
          return false;
        }
        else {
          return true;
        }
      }
      else {
        return true;
      }

    }
  },
});
