<template>
  <Transition name="fade">
    <v-alert
      class="error-alert"
      v-if="this.$store.state.error"
      type="error"
      color="var(--app-red-1)"
    >
      {{ this.$store.state.errorText }}
      <template v-slot:prepend>
        <v-icon> mdi-alert </v-icon>
      </template>
    </v-alert>
  </Transition>
</template>
<script>
export default {
  data: () => ({}),
  methods: {},
  computed: {},
  mounted() {},
};
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s;
  max-height: 100vh;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  max-height: 0px;
}
.error-alert {
  z-index: 1000;
  position: fixed;
  top: 0px;
  width: 100vw;
  border-radius: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  i {
    margin-right: 20px;
  }
}
@media screen and (max-width: 640px) {
  .error-alert {
    font-size: 16px !important;
    height: 70px;
  }
}
</style>