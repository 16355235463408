import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueCookies from "vue-cookies";
import VueMoment from "vue-moment";
import VueClipboard from "vue-clipboard2";
import DialogImage from "./components/main/dialog/DialogImage";
import VueMask from "v-mask";
import Axios from "axios";
import Trumbowyg from "vue-trumbowyg";
import i18n from './i18n'

import "trumbowyg/dist/ui/trumbowyg.css";
import "@/mixins/mixin";

Vue.config.productionTip = false;
Vue.component("DialogImage", DialogImage);
Vue.use(VueCookies);
Vue.use(VueMoment);
Vue.use(VueMask);
Vue.use(Trumbowyg);
Vue.prototype.$axios = Axios;

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
