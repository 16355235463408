<template>
  <v-app>
    <ErrorAlert />
    <SuccessAlert />
    <router-view />
  </v-app>
</template>
<script>
import ErrorAlert from "@/components/main/alerts/ErrorAlert";
import SuccessAlert from "@/components/main/alerts/SuccessAlert";

export default {
  components: {
    ErrorAlert,
    SuccessAlert,
  },
  data: () => ({
    success: false,
    isWide : false,
  }),
  methods: {
    handleResize(){
      this.isWide = window.innerWidth >= window.innerHeight
      this.$store.commit('setIsWide', this.isWide)
    }
  },
  created() { 
    this.handleResize()
  },
  mounted() {
    if (window.PANEL_HTML_PAGE_TITLE) {
      document.title = window.PANEL_HTML_PAGE_TITLE;
    }

    window.addEventListener('resize', this.handleResize)
  },
};
</script>
<style lang="scss">
@import "styles/fonts.scss";

@import "styles/vuetifyEditStyles.scss";
@import "styles/main.scss";
@import "styles/button.scss";
@import "styles/colors.scss";

* {
  font-family: "Pragmatica";
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

html {
  overflow: auto !important;
}

body {
  width: 100vw;
  height: 100vh;

  // background: var(--app-black-7);
  // min-width: 1000px;
  a {
    // text-decoration: none;
    color: var(--app-ros-blue-1);
  }

  touch-action: manipulation;
}

// body::-webkit-scrollbar {
//   width: 0.5rem;
//   background: #e7e2f5;
//   position: absolute;
// }

// body::-webkit-scrollbar-track {
//   // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
// }

// body::-webkit-scrollbar-thumb {
//   border-radius: 8px;

//   border: none !important;
//   background-color: #bdacef;
//   // outline: 1px solid slategrey;
// }
</style>
