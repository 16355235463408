import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VueCookies from "vue-cookies";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    protocol: window.PANEL_HTTP_PROTOCOL,
    host: window.PANEL_HOST,
    apiHost: window.PANEL_API_HOST,
    port: window.PANEL_PORT,
    protocolWS: window.PANEL_SOCKETS_PROTOCOL,
    apiAdminPanelV1: "/api/v1",
    apiAdminPanelV2: "/api/v1/adminPanel",
    secretKey: window.PANEL_API_KEY,
    adminPanelKeyCookie: `ap_${window.PANEL_API_KEY}`,
    user: {},
    mailTo: "",
    error: false,
    errorText: "",
    success: false,
    successText: "",
    isWide: '',
  },
  getters: {
    apiAdminPanelV1: (state) => {
      return (
        state.protocol +
        "://" +
        state.apiHost +

        state.apiAdminPanelV1
      );
    },
    apiAdminPanelV2: (state) => {
      return (
        state.protocol +
        "://" +
        state.apiHost +
        state.apiAdminPanelV2
      );
    },
    apiMobileV1: (state) => {
      return (
        state.protocol +
        "://" +
        state.apiHost +
        ":" +
        state.port +
        state.apiMobileV1
      );
    },
    baseURL: (state) => {
      return state.protocol + "://" + state.host + ":" + state.port;
    },
    wsURL: (state) => {
      return (
        state.protocolWS + "://" + state.host + ":" + state.port + "/webSocket"
      );
    },
    wsChatURL: (state) => {
      return (
        state.protocolWS +
        "://" +
        state.apiHost +
        ":" +
        state.port +
        "/chat/webSocket"
      );
    },
    adminPanelHeaderAuth: (state) => {
      return "Bearer " + VueCookies.get(state.adminPanelKeyCookie);
    },
    secretKey: (state) => {
      return state.secretKey;
    },
    user: (state) => {
      return state.user;
    },
    mailTo: (state) => {
      return state.mailTo;
    },
    visiblePage: (state) => (key) => {
      const newItem = state.user.sections.filter((i) => i.key == key);
      let val = false;
      if (newItem[0]) {
        newItem[0].accesses.forEach((element) => {
          if (element.value) {
            val = true;
          }
        });
      }
      return val;
    },
    editRoleUser: (state) => (val) => {
      const neededObj = state.user.sections.filter((i) => i.key == val);
      const result = neededObj[0].accesses.filter((i) => {
        if (i.key == "edit" && i.value) {
          return i;
        }
      });
      if (result[0]) {
        return true;
      } else {
        return false;
      }
    },
    adminRoleUser: (state) => (val) => {
      const neededObj = state.user.sections.filter((i) => i.key == val);
      const result = neededObj[0].accesses.filter((i) => {
        if (i.key == "super_admin" && i.value) {
          return i;
        }
      });
      if (result[0]) {
        return true;
      } else {
        return false;
      }
    },
    getWide: (state) => {
      return state.isWide
    },

  },
  mutations: {
    userSet(state, user) {
      state.user = user;
    },
    errorSet(state, boolean) {
      state.error = boolean;
      setTimeout(() => {
        state.error = false;
      }, 4000);
    },
    errorTextSet(state, err) {
      if (err && typeof err != "string") {
        if (err.response.data) {
          state.errorText = err.response.data;
        } else {
          state.errorText = "Проблема с подключением";
        }
      } else if (err && typeof err === "string") {
        state.errorText = err;
      } else {
        state.errorText = "Ошибка отправки данных";
      }
    },
    successSet(state, boolean) {
      state.success = boolean;
      setTimeout(() => {
        state.success = false;
      }, 4000);
    },
    successTextSet(state, value) {
      state.successText = value;
    },
    setIsWide(state, value){
      // generates a postfix for css class
      state.isWide = value ? '-wide' : ''
    }
  },
  actions: {
    authorizationAdminPanel: function (context) {
      return axios
        .get(context.getters.apiAdminPanelV1 + "/auth/check", {
          headers: {
            'secret-key': context.getters.secretKey,
            authorization: context.getters.adminPanelHeaderAuth,
          },
        })
        .then((res) => {
          if (res.data?.user) {
            context.commit("userSet", res.data.user);
            return true;
          } else {
            return false;
          }
        })
        .catch(() => {
          return false;
        });
    },
  },
  modules: {},
});
