import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import store from "../store/index";
const routes = [
  {
    path: "/",
    name: "LayoutMain",
    component: () => import("../views/main/Layout.vue"),
    children: [
      {
        path: "/main",
        name: "MainPage",
        component: () => import("../views/main/MainPage.vue"),
      },
      {
        path: "/partner",
        name: "PartnerAdd",
        component: () => import("../views/main/partners/PartnerAdd.vue"),
      },
      {
        path: "/partner/detail/:code",
        name: "Partner",
        component: () => import("../views/main/partners/Partner.vue"),
      },
      {
        path: "/partners",
        name: "Partners",
        component: () => import("../views/main/partners/Partners.vue"),
      },
      
      {
        path: "/cases",
        name: "CasesSuccess",
        component: () => import("../views/main/cases/CasesSuccess.vue"),
      },
      {
        path: "/case/:code",
        name: "Case",
        component: () => import("../views/main/cases/Case.vue"),
      },
      {
        path: "/case/:code/project/:id",
        name: "CaseProject",
        component: () => import("../views/main/cases/CaseProject.vue"),
      },
      {
        path: "/questionnaires",
        name: "Questionnaires",
        component: () => import("../views/main/team/Questionnaires.vue"),
      },

      {
        path: "/questionnaire/card/:id",
        name: "Questionnaire",
        component: () => import("../views/main/team/Questionnaire.vue"),
      },
      {
        path: "/questionnaire/add",
        name: "QuestionnaireAdd",
        component: () => import("../views/main/team/QuestionnaireAdd.vue"),
      },


      {
        path: "/info",
        name: "Info",
        component: () => import("../views/main/Info.vue"),
      },

      {
        path: "/quest",
        name: "Quest",
        component: () => import("../views/main/quest/Quest.vue"),
      },
      {
        path: "/wantwork",
        name: "WantWork",
        component: () => import("../views/main/WantWork.vue"),
      },
    ]
  },
  {
    path: "/wantwork/form",
    name: "WantWorkAdd",
    component: () => import("../views/main/WantWorkAdd.vue"),
  },
  {
    path: '/YWRtaW5QYW5lbFJvc01vbG9kZXpo',
    name: 'Login',
    component: () => import("../views/Login.vue"),
    async beforeEnter(to, from, next) {
      if (await store.dispatch('authorizationAdminPanel')) {
        next({ name: 'LayoutAP' })
      } else {
        next()
      }
    },
  },
  {
    path: '/YWRtaW5QYW5lbFJvc01vbG9kZXpo/adminPanel',
    name: 'LayoutAP',
    component: () => import("../views/ap/LayoutAP.vue"),
    async beforeEnter(to, from, next) {

      if (await store.dispatch('authorizationAdminPanel')) {
        next()
      } else {
        next({ name: 'Login' })
      }
    },
    children: [

      {
        path: 'questionnaires',
        name: 'QuestionnairesAP',
        component: () => import("../views/ap/questionnaire/Questionnaires.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch('authorizationAdminPanel')) {
            next()
          } else {
            next({ name: 'Login' })
          }
        },

      },

      {
        path: 'schedule',
        name: 'Schedule',
        component: () => import("../views/ap/schedule/Schedule.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch('authorizationAdminPanel')) {
            next()
          } else {
            next({ name: 'Login' })
          }
        },

      },




    ]
  }


];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
